import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminLoginPage from './screens/AdminLoginPage';
import AdminDashBoard from './screens/admin/AdminDashBoard';
import { useSession } from './contexts/SessionContext';
import AdminForgotPassword from './screens/AdminForgotPassword';
import VerifyAdminEmail from "./screens/VerifyAdminEmail"
import AdminResetPassword from "./screens/AdminResetPassword"
import MerchantDetailedView from './screens/admin/MerchantDetailedView';
import MerchantProductList from './screens/admin/MerchantProductList';
import MerchantProductView from './screens/admin/MerchantProductView';
import Feedbacks from './screens/admin/Feedbacks';
import "./styling/fonts.css"

function App() {
  const { sessionId } = useSession();

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={sessionId ? <Navigate to="/admin-dashboard" /> : <AdminLoginPage />} />
          <Route path="/admin-dashboard" element={sessionId ? <AdminDashBoard /> : <Navigate to="/" />} />
          <Route path="/forgotPW" element={<AdminForgotPassword/>} />
          <Route path="/verifyAdminEmail" element={<VerifyAdminEmail/>} />
          <Route path="/resetPassword" element={<AdminResetPassword/>} />
          <Route path="/merchant/:id" element={<MerchantDetailedView/>} />
          <Route path="/merchant_items/:id" element={<MerchantProductList/>} />
          <Route path="/merchant/items/:itemId" element={<MerchantProductView/>} />
          <Route path="/feedbacks" element={<Feedbacks/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
