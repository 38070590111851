import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../contexts/SessionContext";
import LoadingWhite from "../images/LoadingWhite.gif";
import "../styling/login.css"
import { adminLoginApi } from "../api/adminLoginApi";
import { setAdminSessionId } from "../session/adminSessionHandler";
import { Link } from "react-router-dom";
import { handleResponseError } from "../utils/handleError";

export default function AdminLoginPage() {
  const navigate = useNavigate();
  const { updateSessionId } = useSession();
  const passwordInputRef = useRef(null);
  const getStartedButtonRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [logginError, setlogginError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEmailKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      passwordInputRef.current.focus();
    }
  };

  const handlePasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getStartedButtonRef.current.click();
    }
  };

  const isButtonActive = email.trim() !== "" && password.trim() !== "";

  const adminLoginHandler = async () => {
    setIsLoading(true);
    const api_data = await adminLoginApi(email, password);
    setIsLoading(false);
    if (api_data.error != null) {
      handleResponseError(api_data.error);
      return;
    }
    await setAdminSessionId(api_data.session_id);
    updateSessionId(api_data.session_id);
    navigate("/admin-dashboard");
  };

  return (
    <div className="loginpage-container">
      <div className="login-content">
        <div></div>
        <div className="welcommingText">
          <h2 className="welcomeText">ADMIN LOGIN</h2>
        </div>
        <form className="loginpageformcontents">
          <input
            type="email"
            placeholder="Email"
            className="login-email-container"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);             
            }}
            onKeyPress={handleEmailKeyPress}

          />
          <input
            type={passwordVisible ? "text" : "password"}
            className="login-password-container"
            ref={passwordInputRef}
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)             
            }}
            onKeyPress={handlePasswordKeyPress}
          />
          <p className="forgot-password-text-style">
            <Link
              to="/forgotPW"
              style={{ color: "#fd9340" }}
              className="forgot-password"
            >
              Forgot Password?
            </Link>
          </p>
          <div >
            <button
              type="button"
              className={`getStartedButton ${isButtonActive ? 'active' : ''}`}
              ref={getStartedButtonRef}
              disabled={!isButtonActive}
              onClick={adminLoginHandler}
              style={{ position: 'relative', overflow: 'hidden', cursor: isButtonActive ? "pointer" : "default", width: '150px' }}
            >
              {isLoading ? (
                <>
                  <img
                    src={LoadingWhite}
                    alt="Loading" className="getstarted-loading-inlogin"

                  />
                </>
              ) : (
                'Get Started'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}