import { BASE_URL, ALL_MERCHENT_DETAILS, FEEDBACK } from "../config";
import { handleResponse } from './responseHandler';

export async function getAllMerchentDetails(sessionId) {
  const response = await fetch(BASE_URL + ALL_MERCHENT_DETAILS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": sessionId,
    },
  });

  return handleResponse(response);
}

export async function getFeedback(sessionId) {
  const response = await fetch(BASE_URL + FEEDBACK, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-USER-SESSION-ID": sessionId,
    },
  });

  return handleResponse(response);
}