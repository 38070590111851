import { BASE_URL,MERCHANTITEMS} from "../config";
import { handleResponse } from './responseHandler';
import { getAdminSessionId } from "../session/adminSessionHandler";

export async function deleteMerchantItem(id,itemId) {
    const response = await fetch(BASE_URL +MERCHANTITEMS +id+'/items'+`/${itemId}`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getAdminSessionId(),
        },
    })

    return handleResponse(response);
}