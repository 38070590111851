import React from "react";
import { useState, useRef } from "react";
import "../styling/adminForgotPassword.css"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingWhite from "../images/LoadingWhite.gif";
import { forgotPassword } from "../api/adminLoginApi";
import { handleResponseError } from "../utils/handleError";

export default function AdminForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [logginError, setlogginError] = useState("");
  const navigate = useNavigate();
  const isButtonActive = email.trim() !== "";
  const verifyButtonRef = useRef(null);

  const handleVerrifyButtonClick = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      verifyButtonRef.current.click();
    }
  }

  const nextHandler = async () => {
    setIsLoading(true);
    const data = await forgotPassword(email);
    setIsLoading(false);

    if (data.error != null) {
     handleResponseError(data.error);
      return;
    }

    if (data) {
      navigate("/verifyAdminEmail", { state: { otp_id: data.otp_id, email: email, }, });
    }
    console.log(data);
  };

  return (
    <div className="forgotmodal-container">
      <div className="forgotmodal-content">
        <h2 className="resetPassword-text">Reset Password</h2>
        <form className="forgotPasswordFormContents">
          <input
            className="forgot-password-email-container"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setlogginError("");
            }}
            onKeyPress={handleVerrifyButtonClick}
          />

          {logginError && (
            <div className="logginError">{logginError}</div>
          )}

          <div >
            <button
              type="button"
              ref={verifyButtonRef}
              className={`verifyForgotButton ${isButtonActive ? "active" : ""} ${isButtonActive ? "customCursorPointer" : "customCursorDefault"}`}
              disabled={!isButtonActive}
              onClick={nextHandler}
              style={{ position: 'relative', overflow: 'hidden', cursor: isButtonActive ? "pointer" : "default", width: '150px' }}
            >
              {isLoading ? (
                <img
                  src={LoadingWhite}
                  alt="Loading" className="getstarted-loading-inforgotpw"
                />
              ) : (
                "Verify"
              )}
            </button>
          </div>
          <p className="haveaccount-text">
            Have an account? {" "}
            <Link to="/" className="haveaccount-link">
              <span className="forgotpassword-loginlink">
                Login
              </span>
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}
