import "../styling/toastHelper.css"
export function displayToast(message, type="error", duration=5000) {
    const toastMessage = document.createElement('div');
    toastMessage.innerText = message;
    toastMessage.style.position = 'fixed';
    toastMessage.style.top = '0';
    toastMessage.style.color = 'white';
    toastMessage.style.padding = '10px';
    if (type === "error") toastMessage.classList.add('toast-message-error');
    else toastMessage.classList.add('toast-message-success');
    document.body.appendChild(toastMessage);
    
    setTimeout(() => {
        document.body.removeChild(toastMessage);
    }, duration);
}