// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backpress-container {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    margin-left: 3rem;
}

@media (max-width: 768px) {
    .backpress-container {
    }
}`, "",{"version":3,"sources":["webpack://./src/styling/BackPress.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;IAChD,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;IACA;AACJ","sourcesContent":[".backpress-container {\n    width: 46px;\n    height: 46px;\n    flex-shrink: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 16px;\n    background: #FFF;\n    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.07);\n    cursor: pointer;\n    margin-left: 3rem;\n}\n\n@media (max-width: 768px) {\n    .backpress-container {\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
