import React, { useState, useRef } from "react";
import "../styling/adminResePassword.css";
import { resetPassword } from "../api/adminLoginApi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingWhite from "../images/LoadingWhite.gif";
import { handleResponseError } from "../utils/handleError";
import { displayToast } from "../utils/toastHelper";

export default function AdminResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmedPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const confirmPasswordRef = useRef(null);
  const resetPasswordButtonRef = useRef(null);
  const navigate = useNavigate();

  let PasswordError;
  const passwordsMatch = newPassword === confirmPassword;
  const isButtonActive =newPassword.trim() !== "" && confirmPassword.trim() !== "" && passwordsMatch;
  
  if (!passwordsMatch) {
    PasswordError = "Password and confirm password does not match.";
  }

  const confirmPasswordPlaceholder = window.innerWidth < 768 ? "Password" : "New Password";

  const resetPasswordHandler = async () => {
    setIsLoading(true);
    const data = await resetPassword(newPassword);
    setIsLoading(false);
    if (data.success) {
      navigate("/", { state: { success: true }, });
      displayToast('Password Update Successful!', "success");
      console.log('displayToast is called')
    }
    if (data.error) return handleResponseError(data.error);
  };

  const handleNewPasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      confirmPasswordRef.current.focus();
    }
  };

  const handleConfirmPasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      resetPasswordButtonRef.current.click();
    }
  };

  return (
    <div className="reset-container">
      <div className="reset-content">
        <h2 className="resetAccount">Reset Password</h2>
        <form className="forminputs">
          <div className="password-input-container">
            <input
              type={ "password" }
              placeholder={confirmPasswordPlaceholder}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="password-text"
              onKeyPress={handleNewPasswordKeyPress}
            />
          </div>
          <div className="password-input-container">
            <input
              type={"password"}
              ref={confirmPasswordRef}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              className="password-text"
              onKeyPress={handleConfirmPasswordKeyPress}
            />
          </div>

          {passwordsMatch ? "" : <div className="password-error-message">{PasswordError}</div>}

          <div className="resetbutton-wrapper">
            <button
              type="button"
              className={`resetButton ${isButtonActive ? "active" : ""
                }`}
              disabled={!isButtonActive}
              onClick={resetPasswordHandler}
              ref={resetPasswordButtonRef}
              style={{ position: 'relative', overflow: 'hidden', cursor: isButtonActive ? "pointer" : "default", width: '200px' }}
            >
              {isLoading ? (
                <>
                  <img
                    src={LoadingWhite}
                    alt="Loading" className="getstarted-loading-inlogin"

                  />
                </>
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
          <p className="haveanaccount-text">
            Have an account?{" "}
            <Link to="/" className="haveanaccount-link">
              <span
                style={{
                  color: "#fd9340",
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
                {" "}
                Login
              </span>
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}