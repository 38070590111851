// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../src/fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../src/fonts/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../src/fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'PoppinsBold'; 
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  }
  @font-face {
    font-family: 'PoppinsSemiBold'; 
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  }
  @font-face {
    font-family: 'Poppins'; 
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  }`, "",{"version":3,"sources":["webpack://./src/styling/fonts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,+DAA+D;EACjE;EACA;IACE,8BAA8B;IAC9B,+DAAmE;EACrE;EACA;IACE,sBAAsB;IACtB,+DAAkE;EACpE","sourcesContent":["@font-face {\n    font-family: 'PoppinsBold'; \n    src: url('../../src/fonts/Poppins-Bold.ttf') format('truetype');\n  }\n  @font-face {\n    font-family: 'PoppinsSemiBold'; \n    src: url('../../src/fonts/Poppins-SemiBold.ttf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Poppins'; \n    src: url('../../src/fonts/Poppins-Regular.ttf') format('truetype');\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
