// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-message-error {
    position: fixed;
    top: 0;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    background-color: #F56A6A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    overflow-x: auto;
    margin-left: calc(50% - 150px);
    margin-top: 20px;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  
  .toast-message-success {
    position: fixed;
    top: 0;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    background-color: #54C41E;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    overflow-x: auto;
    margin-left: calc(50% - 150px);
    margin-top: 14px;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }`, "",{"version":3,"sources":["webpack://./src/styling/toastHelper.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,8BAA8B;IAC9B,gBAAgB;IAChB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,8BAA8B;IAC9B,gBAAgB;IAChB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;EAClB","sourcesContent":[".toast-message-error {\n    position: fixed;\n    top: 0;\n    color: white;\n    padding: 10px;\n    border-radius: 5px;\n    font-size: 14px;\n    background-color: #F56A6A;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 300px;\n    overflow-x: auto;\n    margin-left: calc(50% - 150px);\n    margin-top: 20px;\n    height: fit-content;\n    text-align: center;\n    font-family: Poppins;\n    font-weight: 500;\n  }\n  \n  .toast-message-success {\n    position: fixed;\n    top: 0;\n    color: white;\n    padding: 10px;\n    border-radius: 5px;\n    font-size: 14px;\n    background-color: #54C41E;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 300px;\n    overflow-x: auto;\n    margin-left: calc(50% - 150px);\n    margin-top: 14px;\n    height: fit-content;\n    text-align: center;\n    font-family: Poppins;\n    font-weight: 500;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
