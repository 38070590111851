import React, { createContext, useContext, useState, useEffect } from "react";
import { getAdminSessionId } from "../session/adminSessionHandler";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(() => localStorage.getItem('sessionId'));

  useEffect(() => {
    const storedSessionId = getAdminSessionId();
    updateSessionId(storedSessionId);
  }, []);

  const updateSessionId = (newSessionId) => {
    setSessionId(newSessionId || null);
  };

  return (
    <SessionContext.Provider value={{
      sessionId, updateSessionId,
    }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};