import { getAdminSessionId } from "../session/adminSessionHandler";
import { BASE_URL, DEACTIVE_MERCHANT} from "../config";
import { handleResponse } from "./responseHandler";

export async function deActiveMerchantApi(id) {
    const response = await fetch(BASE_URL + DEACTIVE_MERCHANT + `/${id}`, {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID":getAdminSessionId(),
        },
        body: JSON.stringify({'status' :'deactivated'})
    })

    return handleResponse(response); 
}