const BASE_URL = "https://uexcess-backend-bawzt2unca-el.a.run.app/";
const ADMIN_LOGIN = "api/v1/authentication/admin/session_login";
const ALL_MERCHENT_DETAILS = "api/v1/admin/merchants";
const APPROVE_MERCHENT = "api/v1/admin/approve_merchant";
const SEND_PASSWORD_OTP = "api/v1/authentication/admin/send_forgot_password_otp";
const VERIFY_PASSWORD_OTP = "api/v1/authentication/admin/verify_forgot_password_otp";
const UPDATE_PASSWORD = "api/v1/admin/update_password";
const DEACTIVE_MERCHANT = "api/v1/admin/deactivate_merchant";
const MERCHANTITEMS = "api/v1/admin/merchant/";
const FEEDBACK = "api/v1/admin/feedbacks";
const MERCHENT_STATUS_UPDATE = "api/v1/admin/merchant_status_update";


export { BASE_URL, ADMIN_LOGIN, ALL_MERCHENT_DETAILS, FEEDBACK, APPROVE_MERCHENT, SEND_PASSWORD_OTP, VERIFY_PASSWORD_OTP, UPDATE_PASSWORD, DEACTIVE_MERCHANT, MERCHANTITEMS, MERCHENT_STATUS_UPDATE };