
import { getAllMerchentDetails } from '../api/getAllMerchentDetails';
import { getCacheValue, setCacheValue } from "../cache/CacheHandler";

const ADMIN_SESSION_CACHE_KEY = 'admin_session_data';

export function getAdminSessionData() {
    return getCacheValue(ADMIN_SESSION_CACHE_KEY);
}

export async function reloadAdminSession() {
    let data = await getAdminSessionData();

    if (data == null || data.sessionId == null) {
        setAdminSessionId(null);
        setCacheValue(ADMIN_SESSION_CACHE_KEY, null, 1200);
        window.location.href = '/';
        return null;
    }

    const response = await getAllMerchentDetails(data.sessionId);
    if (response.error) {
        data.sessionId = null;
        setAdminSessionId(null);
        window.location.href = '/';
    } else {
        data.merchantDetails = response;
    }
    setCacheValue(ADMIN_SESSION_CACHE_KEY, data, 1200);
    return data;
}

export function isAdminSessionActive() {
    const data = getAdminSessionData();
    return !(data == null || data.sessionId == null);
}

export function getAdminSessionMerchantDetails() {
    const data = getAdminSessionData();
    if (data != null) return data.merchantDetails;
    return null;
}

export async function setAdminSessionId(sessionId) {
    let data = {};
    data.sessionId = sessionId;

    if (sessionId != null) {
        data.merchantDetails = await getAllMerchentDetails(data.sessionId);
        if (data.merchantDetails == null) {
            data.sessionId = null;
        }
    }
    
    return setCacheValue(ADMIN_SESSION_CACHE_KEY, data, 60);
   
}

export function getAdminSessionId() {
    const data = getAdminSessionData();
    if (data != null) return data.sessionId;
    return null;
}
