import { useState, useEffect } from "react";
import "../../styling/MerchantItemList.css";
import { merchantItemListApi } from "../../api/merchantItemListApi";
import { handleResponseError } from "../../utils/handleError";
import { useLocation } from 'react-router-dom';
import LoadingSpinner from "../../components/LoadingSpinner";
import Item from "../../components/item/Item";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { BackPress } from "../../components/BackPress ";

const MerchantProductList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const merchantId = location.state?.merchant_Id || null;
  const [isLoading, setIsLoading] = useState(false);
  const [onSaleItems, setOnSaleItems] = useState([]);
  const [soldItems, setSoldItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [onSalePageCount, setOnSalePageCount] = useState(0);
  const [onSoldPageCount, setOnSoldPageCount] = useState(0);
  const productsPerPage = 12;
  const [seller_page_item_toggle_state, setSeller_page_item_toggle_state] = useState('onSale');
  const itemsToShow = seller_page_item_toggle_state === "onSale" ? onSaleItems : soldItems;

  const itemsSoldButtonClass =
    "item-status-tab-button-items-sold item-status-tab-button " + (seller_page_item_toggle_state === "itemsSold" ? "item-status-tab-button-selected" : "");

  const onSaleButtonClass =
    "item-status-tab-button-items-on-sale item-status-tab-button " + (seller_page_item_toggle_state === "onSale" ? "item-status-tab-button-selected" : "");

  async function fetchOnSaleProductDetails() {
    setIsLoading(true);
    try {
      setOnSaleItems([])
      const url = `status=active&count=${productsPerPage}&page=${currentPage}`;

      const data = await merchantItemListApi(merchantId, url);
      console.log(data);
      if (data.error) {
        handleResponseError(data.error);
      } else {
        setOnSaleItems(data.items);
        setOnSalePageCount(Math.ceil(data.total / productsPerPage))
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchSoldProductDetails() {
    setIsLoading(true);
    try {
      setSoldItems([])
      const url = `status=sold&$count=${productsPerPage}&page=${currentPage}`;

      const data = await merchantItemListApi(merchantId, url);
      console.log(data);
      if (data.error) {
        handleResponseError(data.error);
      } else {
        setSoldItems(data.items);
        setOnSalePageCount(Math.ceil(data.total / productsPerPage))
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchOnSaleProductDetails();
    fetchSoldProductDetails()
    setOnSaleItems();
  }, [currentPage, seller_page_item_toggle_state]);

  const handleOnSaleClick = async () => {
    setSeller_page_item_toggle_state("onSale");
    await fetchOnSaleProductDetails();
  };

  const handleItemsSoldClick = async () => {
    setSeller_page_item_toggle_state("itemsSold");
    await fetchSoldProductDetails();
  }


  const handlePageClick = (data) => {
    setCurrentPage(parseInt(data.selected) + 1);
  };

  const handleItemClick = (item) => {
    navigate('/merchant/items/' + item.id, {
      state: {
        merchant_Id: merchantId,
        selectedItem: item
      },
    });
  };

  const goBack = () => {
    window.history.back();
};

  return (
    <div className="rootContainer">
      <div  id="product-detail-back-id">
        <BackPress onClick={goBack} />
      </div>
      <div className="item-status-tab-container">
        <button className={onSaleButtonClass} onClick={handleOnSaleClick}>On sale</button>
        <button className={itemsSoldButtonClass} onClick={handleItemsSoldClick} >Items sold</button>
      </div>

      {isLoading ?
        <div style={{ width: '100%' }}>
          <div style={{ paddingTop: '5%', paddingBottom: '10%' }}> <LoadingSpinner /> </div>
        </div> :
        <div className="item-list-container">
          {itemsToShow && itemsToShow.length === 0 ? (
            <div className="no-items">No items found</div>
          ) : (
            itemsToShow && itemsToShow.length > 0 && itemsToShow.map((item) => (
              <Item item={item} handleItemClick={handleItemClick} />
            )))}
        </div>
      }

      {itemsToShow && itemsToShow.length > 0 && (
        <div className="filter-pagination-for-seller-page">
          <ReactPaginate
            pageCount={seller_page_item_toggle_state === 'itemsSold' ? onSoldPageCount : onSalePageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination10"}
            activeClassName={"active"}
            previousClassName={"previous"}
            nextClassName={"next"}
            previousLabel={"<"}
            nextLabel={">"}
            forcePage={currentPage - 1}
            pageRangeDisplayed={2}
          />
        </div>)}
    </div>
  );
}

export default MerchantProductList;