import { BASE_URL, APPROVE_MERCHENT, MERCHENT_STATUS_UPDATE } from "../config";
import { handleResponse } from "./responseHandler";
import { getAdminSessionId } from "../session/adminSessionHandler";

export async function approveMerchent(merchant_id){
    const response = await fetch(BASE_URL + APPROVE_MERCHENT, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getAdminSessionId(),
        },
        body: JSON.stringify({
            merchant_id: merchant_id,
        }),
    });
   
    return handleResponse(response);
}

export async function verifyMerchent(merchant_id){
    const response = await fetch(BASE_URL + MERCHENT_STATUS_UPDATE, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-USER-SESSION-ID": getAdminSessionId(),
        },
        body: JSON.stringify({
            merchant_id: merchant_id,
        }),
    });
   
    return handleResponse(response);
}