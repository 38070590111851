import { BASE_URL, ADMIN_LOGIN, SEND_PASSWORD_OTP, UPDATE_PASSWORD} from "../config";
import { getAdminSessionId } from "../session/adminSessionHandler";
import { handleResponse } from "./responseHandler";

export async function adminLoginApi(email, password) {
    const response = await fetch(BASE_URL + ADMIN_LOGIN, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            password: password,
        }),
    });

    return handleResponse(response); 
}

export async function forgotPassword(email) {
    const response = await fetch(BASE_URL + SEND_PASSWORD_OTP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
    });

    return handleResponse(response);
}

export async function resetPassword(newPassword) {
    const response = await fetch(BASE_URL + UPDATE_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-USER-SESSION-ID": getAdminSessionId(),
        },
        body: JSON.stringify({
          password: newPassword,
        }),
    });

    return handleResponse(response);
}