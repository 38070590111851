import "../../styling/adminDashboard.css"
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSession } from '../../contexts/SessionContext';
import LoadingSpinner from "../../components/LoadingSpinner"
import { getAdminSessionId } from '../../session/adminSessionHandler';
import { setAdminSessionId } from '../../session/adminSessionHandler';
import { getAllMerchentDetails } from '../../api/getAllMerchentDetails';

const AdminDashBoard = () => {
  const navigate = useNavigate();
  const { updateSessionId } = useSession();
  const adminSessionId = getAdminSessionId();
  const [merchants, setMerchants] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailFilter, setEmailFilter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');

  useEffect(() => {
    async function fetchMerchantData() {
      setIsLoading(true);
      if (adminSessionId) {
        const merchantData = await getAllMerchentDetails(adminSessionId);
        setMerchants(merchantData);
      }
      else {
        navigate('/')
      }
      setIsLoading(false);
    }
    fetchMerchantData();
  }, [adminSessionId]);

  function renderAction(merchant) {
    if (merchant.status === 'in_review') {
      if (merchant.isActivated) {
        return 'Activated';
      } else {
        return 'Under_review'
      }
    } else if (merchant.status === 'active') {
      return 'Activated';
    } else if (merchant.status === 'afa_pending') {
      return 'Pending';
    } else if (merchant.status === 'deactivated') {
      return 'Deactivated';
    }
  }

  const handleLogout = () => {
    updateSessionId(null);
    setAdminSessionId(null);
    navigate('/');
  };

  const handleMerchantClick = (merchant) => {
    navigate(`/merchant/${merchant.id}`, {
      state: {
        merchantData: merchant,
        merchants: merchants,
      },
    });
  };

  const handleFeedback = async () => {
    navigate('/feedbacks')
  }

  return (
    <div className='adminPageContainer'>
      <div className='adminPageContent'>
        <div>
          <div className='adminPageHeadingContainer'>
            <div className='main-dropdown-container'>
              <select
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                className="dropdown-container"
              >
                <option value="all">All Merchants</option>
                <option value="active">Active Merchants</option>
                <option value="afa_pending">Pending Merchants</option>
                <option value="in_review">Under Review</option>
                <option value="deactivated">Deactivated Maerchants</option>
              </select>
            </div>
            <h1 style={{ color: '#FD9340', fontSize: '45px', fontFamily: "PoppinsBold", position: 'relative', width: '50%', left: '9%' }}>Admin Dashboard</h1>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
          <div className='filter-main-container'>
            <div className='feedback-container'>
              <button className='feedback-button' onClick={handleFeedback}>Feedback Details</button>
            </div>
            <input
              type="text"
              placeholder="Filter by name"
              value={nameFilter}
              className="filter-container"
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <input
              type="text"
              placeholder="Filter by email"
              value={emailFilter}
              className="filter-container"
              onChange={(e) => setEmailFilter(e.target.value)}
            />
          </div>
          <div className='merchentDetail-heading'>
            <div className='merchentDetail-text' id="merchentDetail-ID">Sl.No</div> 
            {/* <div className='merchentDetail-text' id="merchentDetail-ID">ID</div> */}
            <div className='merchentDetail-text' id="merchentDetail-Name">Name</div>
            <div className='merchentDetail-text' id="merchentDetail-Email">Email</div>
            <div className='merchentDetail-text' id="merchentDetail-Status">Status</div>
            <div className='merchentDetail-text' id="merchentDetail-Action">Action</div>
          </div>
          <div >
            {isLoading ?
              <div style={{ width: '100%' }}>
                <div style={{ paddingTop: '5%', paddingBottom: '10%', paddingRight: '5%' }}> <LoadingSpinner /> </div>
              </div> : (
                <div className="merchant-details-container" >
                  {merchants
                    .filter((merchant) => merchant.name.toLowerCase().startsWith(nameFilter.toLowerCase()) &&
                      merchant.email.toLowerCase().startsWith(emailFilter.toLowerCase()) &&
                      (selectedFilter === 'all' || merchant.status === selectedFilter)
                    )
                    .map((merchant, index) => (
                      <button className="merchant-detail" key={merchant.id} onClick={() => { handleMerchantClick(merchant) }}>
                        <div className="detail" id="detail-id">{index + 1}</div>
                        {/* <div className="detail" id="detail-id">{merchant.id}</div> */}
                        <div className="detail" id="detail-name">{merchant.name}</div>
                        <div className="detail" id="detail-email">{merchant.email}</div>
                        <div className="detail" id="detail-status">
                          <div
                            style={{
                              color:
                                merchant.status === 'active' ? 'green' : merchant.status === 'afa_pending' ? 'red' : merchant.status === 'deactivated' ? 'orange' : 'blue',
                            }}
                          >
                            {merchant.status}
                          </div>
                        </div>
                        <div className="detail" id="detail-action">
                          {renderAction(merchant)}
                        </div>
                      </button>
                    ))}
                  {merchants
                    .filter((merchant) =>
                      merchant.name.toLowerCase().startsWith(nameFilter.toLowerCase()) &&
                      merchant.email.toLowerCase().startsWith(emailFilter.toLowerCase()) &&
                      (selectedFilter === 'all' || merchant.status === selectedFilter)
                    )
                    .length === 0 && (
                      <div className="no-results-message-container">
                        <div className="no-results-message">No results found</div>
                      </div>
                    )}
                </div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashBoard;
