import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import "../../styling/MerchantProductView.css";
import { BackPress } from '../../components/BackPress ';
import SliderImage from '../../components/SliderImage';
import { isNumber } from '../../utils/numberHelper';
import "../../styling/MySliderComponent.css";
import "react-awesome-slider/dist/styles.css";
import Modal from "react-modal";
import DeleteHandler from '../DeleteHandler';

const MerchantProductView = () => {
    const location = useLocation();
    const item = location.state?.selectedItem || {};
    const merchant_Id = location.state?.merchant_Id || null;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const goBack = () => {
        window.history.back();
    };

    const handleDeleteProduct = () => {
        setIsDeleteModalOpen(true);
    };

    function deleteBackHandler() {
        setIsDeleteModalOpen(false);
    }

    return (
        <div>
            <Modal
                isOpen={isDeleteModalOpen}
                contentLabel="Popup"
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    content: {
                        border: "none",
                        background: "none",
                        padding: 0,
                        margin: 0,
                    },
                }}>
                <DeleteHandler itemId={item.id} backHandler={deleteBackHandler} merchantId={merchant_Id} />
            </Modal>

            <div className='detail-container'>
                <div className='product-detail-container'>
                    <div className='product-detail-container-header'>
                        <div className="product-detail-back">
                            <BackPress onClick={goBack} />
                        </div>
                        <div className='product-detail-container-header-title'>
                            {item.catogery_type}
                        </div>
                        <div className='product-feature-delete' onClick={handleDeleteProduct}>
                            {(item.status === 'active') && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 35 39" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0383 6.83056C33.7678 6.83056 34.375 7.43618 34.375 8.2068V8.91929C34.375 9.67116 33.7678 10.2955 33.0383 10.2955H1.96348C1.23224 10.2955 0.625 9.67116 0.625 8.91929V8.2068C0.625 7.43618 1.23224 6.83056 1.96348 6.83056H7.43044C8.54097 6.83056 9.50744 6.0412 9.75727 4.92746L10.0436 3.64872C10.4885 1.90686 11.9528 0.75 13.6286 0.75H21.3714C23.029 0.75 24.5097 1.90686 24.9382 3.55685L25.2446 4.92558C25.4926 6.0412 26.459 6.83056 27.5714 6.83056H33.0383ZM30.2609 32.8763C30.8317 27.557 31.831 14.9196 31.831 14.7921C31.8675 14.4059 31.7416 14.0402 31.4918 13.7459C31.2237 13.4702 30.8846 13.3071 30.5107 13.3071H4.50348C4.12783 13.3071 3.77042 13.4702 3.52242 13.7459C3.27077 14.0402 3.14677 14.4059 3.165 14.7921C3.16835 14.8155 3.20421 15.2607 3.26416 16.0049C3.53047 19.311 4.27219 28.5191 4.75148 32.8763C5.09066 36.0863 7.19684 38.1038 10.2476 38.1769C12.6018 38.2313 15.0271 38.25 17.5071 38.25C19.8431 38.25 22.2155 38.2313 24.6426 38.1769C27.7991 38.1225 29.9035 36.1406 30.2609 32.8763Z" fill="#130F26" />
                                </svg>
                            )}
                        </div>
                    </div>
                    <div className='product-detail-container-body'>
                        <div>
                            <div className='product-detail-container-main'>
                                <div className='product-detail-container-main-left'>
                                    <div className="product-images-container">
                                        <SliderImage data={item} />
                                    </div>
                                </div>
                                <div className="product-features-container">
                                    <div className='product-feature-header'>
                                        <div className="product-feature-header-title">{item.catogery_type}</div>
                                    </div>
                                    <div className='description-container'>
                                        {item.product_description
                                            ? item.product_description.length > 100
                                                ? item.product_description.substring(0, 100) + "..."
                                                : item.product_description
                                            : 'No Description available'}                                         
                                    </div>
                                    {Array.isArray(item.parameters) && item.parameters.length > 0 ? (
                                        <div className={item.quantity == null && item.price == null ? "product-feature-parameters product-feature-parameters" : "product-feature-parameters"}>
                                            {item.parameters.map((parameter, index) => (
                                                <div className='merchent-item-container' >
                                                    <p className='merchent-heading-text' id="product-name-text"> {parameter.name}:</p>
                                                    <div className='merchent-value-text' id="product-value-text"> {parameter.value} {parameter.um}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        null
                                    )}
                                    <div className="product-feature-trade">
                                        {isNumber(item.quantity) ? (
                                            <div className="product-feature-quantity-container">
                                                <div className="product-feature-quantity">{item.quantity != null ? item.quantity + item.quantity_um : ''}
                                                </div>
                                            </div>
                                        ) : null}
                                        {isNumber(item.price) ? (
                                            <div className="product-feature-price-container">
                                                <div className="product-feature-price">{item.price != null ? 'Rs ' + item.price : ''}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MerchantProductView;
