import "../../styling/adminDashboard.css"
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import LoadingSpinner from "../../components/LoadingSpinner"
import { handleResponseError } from "../../utils/handleError";
import { getFeedback } from '../../api/getAllMerchentDetails';
import { getAdminSessionId } from '../../session/adminSessionHandler';

const Feedbacks = () => {
    const navigate = useNavigate();
    const adminSessionId = getAdminSessionId();
    const [feedback, setFeedback] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackEmailFilter, setFeedbackEmailFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (adminSessionId) {
                    const feedbackData = await getFeedback(adminSessionId);
                    if (Array.isArray(feedbackData.items)) {
                        setFeedback(feedbackData.items);
                    }
                    if (feedback.error) {
                        handleResponseError(feedback.error.description);
                    }

                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching feedback data:', error);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [adminSessionId, setFeedback]);

    const closeFeedback = () => {
        navigate('/admin-dashboard')
    }

    return (
        <div className='adminPageContainer'>
            <div className='adminPageContent'>
                <div>
                    <div className='adminPageHeadingContainer'>
                        <h1 style={{ color: '#FD9340', fontSize: '45px', fontFamily: "PoppinsBold", position: 'relative', width: '90%', left: '35%' }} >Feedback Details</h1>
                        <button class="close-button" onClick={closeFeedback}>
                            &times;
                        </button>
                    </div>
                    <div className='filter-main-container'>
                        <input
                            type="text"
                            placeholder="Filter by email"
                            value={feedbackEmailFilter}
                            className="filter-container"
                            onChange={(e) => setFeedbackEmailFilter(e.target.value)}
                        />
                    </div>
                    <div >
                        {isLoading ?
                            <div style={{ width: '100%' }}>
                                <div style={{ paddingTop: '5%', paddingBottom: '10%', paddingRight: '5%' }}> <LoadingSpinner /> </div>
                            </div> : (<div className="feedback-details-container" >
                                {
                                    feedback
                                        .filter((item) =>
                                            item.merchant_email && item.merchant_email.toLowerCase().startsWith(feedbackEmailFilter.toLowerCase())
                                        )
                                        .map((item, index, self) => (
                                            <div
                                                className="feedback-main-container"
                                                key={item.id}
                                                style={self.length % 2 !== 0 && index === self.length - 1 ? { width: '40%', marginLeft: '30%' } : {}}
                                            >
                                                <div className="id-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <text className="id">ID</text>
                                                    </div>
                                                    <div className="id-value-container" style={{ width: '70%', textAlign: 'start' }}>
                                                        <text className="id-value">{item.id}</text>
                                                    </div>
                                                </div>
                                                <div className="id-container" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4%' }}>
                                                    <div>
                                                        <text className="id">Email</text>
                                                    </div>
                                                    <div className="id-value-container" style={{ width: '70%', textAlign: 'start' }}>
                                                        <text className="id-value">{item.merchant_email}</text>
                                                    </div>
                                                </div>
                                                <text className="feedback">Feedback</text>
                                                <div className="feedbacks-container">

                                                    <text>{item.feedback}</text>
                                                </div>
                                            </div>
                                        ))}
                                {feedback
                                    .filter((item) =>
                                        item.merchant_email && item.merchant_email.toLowerCase().startsWith(feedbackEmailFilter.toLowerCase())
                                    )
                                    .length === 0 && (
                                        <div className="no-results-message-container">
                                            <div className="no-results-message">No results found</div>
                                        </div>)}
                            </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feedbacks;
