import React from 'react';
import LoadingWhite from '../../images/LoadingWhite.gif';
import "../../styling/MerchantDetailedView.css"
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { approveMerchent, verifyMerchent } from '../../api/approveMerchent';
import { handleResponseError } from '../../utils/handleError';
import { useNavigate } from 'react-router-dom';
import DeActivateConfirmationPopup from './DeActivateConfirmationPopup ';
import { deActiveMerchantApi } from '../../api/deActiveMerchantApi';

const MerchantDetailedView = () => {
  const location = useLocation();
  const merchantData = location.state?.merchantData || null;
  const merchants = location.state?.merchants || null;
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const navigate = useNavigate();

  const handleDeactivateMerchant = async (merchantId) => {
    setShowConfirmationPopup(true);
  };

  const handleApproveMerchant = async (merchantId) => {
    setIsLoading(true);
    try {
      const response = await approveMerchent(merchantId);
      if (!response.error) {
        const updatedMerchants = merchants.map(merchant => {
          if (merchant.id === merchantId) {
            merchant.status = 'active';
          }
          return { ...merchant, isActivated: true };

        });
        location.state.setMerchants(updatedMerchants);

      } else {
        handleResponseError(response.error);
        navigate('/admin-dashboard');
      }
    } catch (error) {
      console.error('Error in approveMerchent:', error);
    }
    setIsLoading(false);
  };

  const handleVerifyMerchant = async (merchantId) => {
    setIsLoading(true);
    try {
      const response = await verifyMerchent(merchantId);
      if (!response.error) {
        const updatedMerchants = merchants.map(merchant => {
          if (merchant.id === merchantId) {
            merchant.status = 'in_review';
          }
          return { ...merchant, isActivated: true };

        });
        location.state.setMerchants(updatedMerchants);

      } else {
        handleResponseError(response.error);
        navigate('/admin-dashboard');
      }
    } catch (error) {
      console.error('Error in approveMerchent:', error);
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    navigate('/admin-dashboard');
  }

  const closeDeletePopupModal = () => {
    setShowConfirmationPopup(false);
  }

  const handleMerchantDeactivate = async (merchantId) => {
    setIsButtonLoading(true);
    try {
      const data = await deActiveMerchantApi(merchantId);
      if (data.error != null) {
        handleResponseError(data.error);
        navigate("/admin-dashboard");
        return


      }
      const updatedMerchants = merchants.map(merchant => {
        if (merchant.id === merchantId) {
          merchant.status = data.status;
        }
        return { ...merchant, isActivated: false };
      });

      location.state.setMerchants(updatedMerchants);
      setShowConfirmationPopup(false);
    } catch (error) {
      console.error('Error in handleMerchantDeactivate:', error);
    } finally {
      setIsButtonLoading(false);
    }
    setShowConfirmationPopup(false);
  };

  const handleMerchantItem = async (merchantId) => {
    navigate(`/merchant_items/${merchantId}`, {
      state: {
        merchant_Id: merchantId,
      },
    });
  };

  return (
    <div>
      {showConfirmationPopup && (
        <DeActivateConfirmationPopup
          getMerchant={merchantData}
          isButtonLoading={isButtonLoading}
          closeDeletePopupModal={closeDeletePopupModal}
          handleMerchantDeactivate={handleMerchantDeactivate}
        />
      )}

      <div className="modal-background" >
        <div className="modal-contentstyle" onClick={(e) => e.stopPropagation()}>
          <div>
            <p className='modal-heading-name'>
              {(merchantData?.name && merchantData?.name).length > 40? merchantData?.name.substring(0, 40) + "...": merchantData?.name}</p>
              <button class="close-modal" onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className='information-container' style={{ marginTop: '3%' }}>
            <div className='merchent-item-container'>
              <p className='merchent-heading-text'>ID :</p>
              <div className='merchent-value-text'> {merchantData?.id}</div>
            </div>
            <div className='merchent-item-container'>
              <p className='merchent-heading-text'>Name :</p>
              <div className='merchent-value-text'> {merchantData?.name}</div>
            </div>
            <div className='merchent-item-container'>
              <p className='merchent-heading-text'>Email :</p>
              <div className='merchent-value-text'> {merchantData?.email}</div>
            </div>
            <div className='merchent-item-container'>
              <p className='merchent-heading-text'>Status :</p>
              <div className='merchent-value-text'>{merchantData?.status}</div>
            </div>
            <div className='merchent-item-container'>
              <p className='merchent-heading-text'>Contact :</p>
              <div className='merchent-value-text'> {merchantData?.contact || 'Not available'}</div>
            </div>
            <div className='merchent-item-container'>
              <p className='merchent-heading-text'>Address :</p>
              <div className='merchent-value-text'> {merchantData?.address || 'Not available'}</div>
            </div>
            {merchantData?.status === 'active' && (
              <div className='merchent-item-container'>
                <p className='merchent-heading-text'>Approved At :</p>
                <div className='merchent-value-text'>{merchantData?.approved_at || 'Not available'}</div>
              </div>)}
          </div>
          <div className='activate-button-container' >
            {(merchantData?.status === "in_review" || merchantData?.status === "deactivated") && (
              <button
                onClick={() => handleApproveMerchant(merchantData?.id)}
                id="activate-button-style"
                style={{ position: 'relative', overflow: 'hidden', width: '150px' }}
              >
                {isLoading ? (
                  <>
                    <img
                      src={LoadingWhite}
                      alt="Loading" className="getstarted-loading-inlogin"
                    />
                  </>
                ) : (
                  'Activate'
                )}
              </button>
            )}

            {(merchantData?.status === "afa_pending") && (
              <button
                onClick={() => handleVerifyMerchant(merchantData?.id)}
                id="activate-button-style"
                style={{ position: 'relative', overflow: 'hidden', width: '200px' }}
              >
                {isLoading ? (
                  <>
                    <img
                      src={LoadingWhite}
                      alt="Loading" className="getstarted-loading-inlogin"
                    />
                  </>
                ) : (
                  'Verify Merchant'
                )}
              </button>
            )}

            {merchantData?.status === "active" && (
              <button
                onClick={() => handleDeactivateMerchant(merchantData?.id)}
                id="activate-button-style"
                style={{ position: 'relative', overflow: 'hidden', width: '150px' }}
              >
                {isLoading ? (
                  <>
                    <img
                      src={LoadingWhite}
                      alt="Loading" className="getstarted-loading-inlogin"
                    />
                  </>
                ) : (
                  'Deactivate'
                )}
              </button>
            )}
            {merchantData?.status === "active" && (
              <button
                onClick={() => handleMerchantItem(merchantData?.id)}
                id="activate-button-style"
                style={{ position: 'relative', overflow: 'hidden', width: '150px' }}
              >
                {isLoading ? (
                  <>
                    <img
                      src={LoadingWhite}
                      alt="Loading" className="getstarted-loading-inlogin"
                    />
                  </>
                ) : (
                  'Item List'
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantDetailedView;
