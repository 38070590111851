import React from 'react';
import "../../styling/DeleteConfirmationPopup .css";
import LoadingWhite from "../../images/LoadingWhite.gif";
import { useState } from 'react';

const DeActivateConfirmationPopup  = ({ getMerchant, isButtonLoading, closeDeletePopupModal, handleMerchantDeactivate }) => {
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    return (
        <div className={`modal-overlay ${isButtonLoading ? 'unclickable' : ''}`} id='modal-overlay' onClick={closeDeletePopupModal}>
            <div className="modalNew-content" onClick={(e) => e.stopPropagation()}>
                <p className='para-style'>Are you sure you want to deactivate merchant with id {getMerchant?.id}?</p>
                <button
                    onClick={() => {
                        setDeleteInProgress(true);
                        handleMerchantDeactivate(getMerchant.id);
                    }}
                    className={`buttonfirst-style`}
                >
                    {isButtonLoading ?
                        (
                            <img src={LoadingWhite} alt="Loading" />
                        ) : (
                            <span>Deactivate</span>
                        )
                    }
                </button>
                <button
                    onClick={closeDeletePopupModal}
                    className={`buttonsecond-style`}
                >
                    No
                </button>
            </div>
        </div>
    );
};

export default DeActivateConfirmationPopup ;
