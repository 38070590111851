import { BASE_URL,MERCHANTITEMS} from "../config";
import { handleResponse } from './responseHandler';
import { getAdminSessionId } from "../session/adminSessionHandler";

export async function merchantItemListApi(id,filter) {
    const response = await fetch(BASE_URL + MERCHANTITEMS+ id +'/items?'+filter, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-USER-SESSION-ID": getAdminSessionId(),
      }, 
    });

    return handleResponse(response);
  }