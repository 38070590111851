
import React from "react";
import { useState, useRef } from "react";
import "../styling/adminVerifyEmail.css"
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setAdminSessionId } from "../session/adminSessionHandler";
import LoadingWhite from "../images/LoadingWhite.gif";
import { BASE_URL, VERIFY_PASSWORD_OTP, SEND_PASSWORD_OTP } from "../config";
import { handleResponseError } from "../utils/handleError";

export default function VerifyAdminEmail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const { otp_id, email } = state || {};
  const [otpValue, setOtpValue] = useState(["", "", "", ""]);
  const [newOtp, setNewOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [resendClicked, setResendClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const continueButtonRef = useRef(null);

  const handleOtpFieldPress = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index === otpValue.length - 1) {
        continueButtonRef.current.click();
      }
    }
  };

  const inputChangeHandler = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const updatedOtpValue = [...otpValue];
      updatedOtpValue[index] = value;
      setOtpValue(updatedOtpValue);
      if (value && index < 3) {
        const nextInput = document.getElementById(`otpInput-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
    setErrorMessage("");
  };

  const handleKeyDown = (index, e) => {
    if ((e.key === "Backspace" || e.key === "Delete") && index >= 0) {
      if (otpValue[index] === "") {
        const previousIndex = Math.max(index - 1, 0);
        const previousInput = inputRefs.current[previousIndex];
        if (previousInput) {
          previousInput.focus();
        }
      } else {
        otpValue[index] = "";
        setOtpValue([...otpValue]);
      }
    }
    setErrorMessage("");
  };

  const isButtonActive = otpValue.every((value) => value !== "");

  const continueButtonClickHandler = () => {
    setIsLoading(true);
    if (resendClicked) {
      resendEmailHandler();
    } else {
      verifyEmailHandler();
    }
  };

  const resendEmailHandler = async () => {
    try {
      const response = await fetch(
        BASE_URL + VERIFY_PASSWORD_OTP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: newEmail,
            otp_id: newOtp,
            otp: otpValue.join(""),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("OTP Verification Successful!");
        setAdminSessionId(data.session_id);
        navigate("/resetPassword");
      } else {
        console.log("OTP Verification Failed.");
        const errorData = await response.json();
        handleResponseError(errorData.error);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyEmailHandler = async () => {
    try {
      const response = await fetch(
        BASE_URL + VERIFY_PASSWORD_OTP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp_id: otp_id,
            otp: otpValue.join(""),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("OTP Verification Successful!");
        setAdminSessionId(data.session_id);
        navigate("/resetPassword");
      }
     else{
      const errorData = await response.json();
      handleResponseError(errorData.error);
     }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setVerificationStatus("error");
      setErrorMessage("An error occurred while verifying OTP.");
    } finally {
      setIsLoading(false);
    }
  };

  const resendOTP = async () => {
    try {
      const response = await fetch(
        BASE_URL + SEND_PASSWORD_OTP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("OTP triggered successfully!");
        setNewEmail(email);
        setNewOtp(data.otp_id);
        await resendEmailHandler(data.otp_id, email);
        setResendClicked(true);
      }
      else {
        console.log("OTP  didnt triggered successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOTP = () => {
    resendOTP(email);
  };

  return (
    <div className="verify-container">
      <div className="verify-content">
        <h2 className="verifyAccount-verifyMail">Verify your Email</h2>
        <div className="otpInputContainer">
          {otpValue.map((value, index) => (
            <input
              key={index}
              id={`otpInput-${index}`}
              className="otpInput"
              type="number"
              maxLength="1"
              value={value}
              onChange={(e) =>
                inputChangeHandler(index, e.target.value)
              }
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(input) => (inputRefs.current[index] = input)}
              onKeyPress={(e) => handleOtpFieldPress(e, index)}
            />
          ))}
        </div>
        <div className="messageContainer">
          <p className="message">
            Please enter the 4-digit code you received on your email.
          </p>
        </div>
        {verificationStatus === "failed" && (
          <div className="error-message-container">{errorMessage}</div>
        )}
        <div className="resendOtptext-container">
          <p className="resendOtptext">
            Did not Receive OTP?{" "}
            <Link
              to=" "
              className="create-account-link"
              onClick={handleResendOTP}
            >
              <span
                style={{
                  color: "#FD9340",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                {" "}
                Resend OTP
              </span>
            </Link>
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            className={`continueButton ${isButtonActive ? "active" : ""
              }`}
            disabled={!isButtonActive}
            style={{ position: 'relative', overflow: 'hidden', cursor: isButtonActive ? "pointer" : "default", width: '150px' }}
            onClick={continueButtonClickHandler}
            ref={continueButtonRef}
          >
            {isLoading ? (
              <>
                <img
                  src={LoadingWhite}
                  alt="Loading" className="getstarted-loading-inforgotpw"
                />
              </>
            ) : (
              "Continue"
            )}
          </button>
        </div>
        <div className="have-accounttext-container">
          <p className="have-accounttext">
            Have an account?{" "}
            <Link to="/" className="have-accountlink">
              <span
                style={{
                  color: "#FD9340",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                Login
              </span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}