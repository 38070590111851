import React, { useState } from 'react';
import '../styling/DeleteHandler.css';
import { useContext } from 'react';
import { deleteMerchantItem } from '../api/deleteMerchantItem';
import LoadingWhite from "../images/LoadingWhite.gif";
import { handleResponseError } from '../utils/handleError';
import { LoadingContext } from '../contexts/LoadingContext';

function DeleteHandler({ backHandler, itemId = null, onClose = null, merchantId }) {
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleBackClick = () => {
        backHandler();
    };

    const handleModalClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            backHandler();
        }
    };

    const handleDelete = async () => {
        if (itemId !== null) {
            setIsButtonLoading(true);
            setIsLoading(true);
            try {
                const data = await deleteMerchantItem(merchantId, itemId);
                if (data.error) {
                    setIsButtonLoading(false);
                    setIsLoading(false);
                    return handleResponseError(data.error);
                }
                window.history.go(-1);
            } catch (error) {
                console.error('Error deleting item:', error);
            }
            finally {
                setIsLoading(false);
                setIsButtonLoading(false);
            }
        }
        if (itemId === null) {
            onClose();
        }
    };

    return (
        <div className={`modal-overlay ${isButtonLoading ? 'unclickable' : ''}`} id='modal-overlay' onClick={handleModalClick} >
            <div className="modalNew-content">
                <p className='para-style'>Are you sure you want to delete?</p>
                <button
                    onClick={() => {
                        setDeleteInProgress(true);
                        handleDelete();
                    }}
                    className={`buttonfirst-style`}
                >
                    {isButtonLoading ?
                        (
                            <img src={LoadingWhite} alt="Loading" />
                        ) : (
                            <span>Delete</span>
                        )
                    }
                </button>
                <button
                    onClick={handleBackClick}
                    className={`buttonsecond-style `}
                >
                    No
                </button>
            </div>
        </div>
    )
}

export default DeleteHandler;
